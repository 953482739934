import { FormContainer, FormComponent } from "react-authorize-net";
import { useHireContext } from '../../App.js';
import { toast } from 'react-toastify';
import axios from "axios";

const PaymentForm = ({topup}) => {
  const { balance, email, setBalance } = useHireContext();
  const apiLoginID = process.env.REACT_APP_API_LOGIN_ID;
  const clientKey = process.env.REACT_APP_CLIENT_KEY;

  const onSuccessHandler = (response) => {
    const newBalance = Number(balance) + Number(topup);
    const data = {
      user: email,
      balance: newBalance
    };
    axios.post("https://Business.ozchest.com/addBalance", data).then((res) => {
      if (res) {
        setBalance(data.balance);
        toast.success('Balance added successfully');
      }
    });
  };

  const onErrorHandler = (response) => {
    const errors = response.messages.message.map((err) => err.text);
    if (errors && errors?.length > 0) {
      toast.error(errors[0]);
    }
  };

  return (
    <div style={{width: "60vw"}}>
      <FormContainer
        environment="production"
        onError={onErrorHandler}
        onSuccess={onSuccessHandler}
        amount={topup}
        component={FormComponent}
        clientKey={clientKey}
        apiLoginId={apiLoginID}
      />
    </div>
  );
};

export default PaymentForm;